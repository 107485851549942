import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import Menu from './components/Menu/Menu';
import HeaderMobile from './components/HeaderMobile/HeaderMobile';
import NotFound from './components/NotFound/NotFound';
import Reservation from './components/Reservation/Reservation';

import './assets/SCSS/App.scss';

function Layout({ children }) {
  const [isReservationPanelOpen, setIsReservationPanelOpen] = useState(false)
  return (
    <div className={isReservationPanelOpen ? "site-bar-open" : ""}>
      <Header
        setIsReservationPanelOpen={setIsReservationPanelOpen}
        isReservationPanelOpen={isReservationPanelOpen}
      />
      <HeaderMobile
        setIsReservationPanelOpen={setIsReservationPanelOpen}
        isReservationPanelOpen={isReservationPanelOpen}
      />
      {isReservationPanelOpen && (
        <Reservation setIsReservationPanelOpen={setIsReservationPanelOpen} />
      )}
      {children}
      <Footer />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout><About /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/menu" element={<Layout><Menu /></Layout>} />
          <Route path="/gallery" element={<Layout><Gallery /></Layout>} />
          <Route path="/contact" element={<Layout><Contact /></Layout>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
