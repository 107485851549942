import React from 'react'
import './Button.scss'

const Button = ({ text, isReservationPanelOpen, setIsReservationPanelOpen }) => {
  return (
    <div>
      <button className='button' onClick={() => setIsReservationPanelOpen(!isReservationPanelOpen)}>{text}</button>
      {/* <a className='button' href="tel:+34603839509">{text}</a> */}
    </div>
  )
}

export default Button
