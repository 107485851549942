import React from 'react'
import './Time.scss'
const Time = ({setChoosenTime}) => {
  return (
    <div className='time'>
      <div className='time__block'>
        <div className='time__time-container' onClick={() => setChoosenTime('13:00')}>
          <p className='time__hour'>13:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('13:30')}>
          <p className='time__hour'>13:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('14:00')}>
          <p className='time__hour'>14:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('14:30')}>
          <p className='time__hour'>14:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('15:00')}>
          <p className='time__hour'>15:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('15:30')}>
          <p className='time__hour'>15:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('16:00')}>
          <p className='time__hour'>16:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('16:30')}>
          <p className='time__hour'>16:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container'onClick={() => setChoosenTime('17:00')}>
          <p className='time__hour'>17:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('17:30')}>
          <p className='time__hour'>17:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('18:00')}>
          <p className='time__hour'>18:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('18:30')}>
          <p className='time__hour'>18:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('19:00')}>
          <p className='time__hour'>19:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('19:30')}>
          <p className='time__hour'>19:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('20:00')}>
          <p className='time__hour'>20:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('20:30')}>
          <p className='time__hour'>20:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('21:00')}>
          <p className='time__hour'>21:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('21:30')}>
          <p className='time__hour'>21:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('22:00')}>
          <p className='time__hour'>22:00</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('22:30')}>
          <p className='time__hour'>22:30</p>
          <p className='time__pm'>PM</p>
        </div>
        <div className='time__time-container' onClick={() => setChoosenTime('23:00')}>
          <p className='time__hour'>23:00</p>
          <p className='time__pm'>PM</p>
        </div>
      </div>
    </div>
  )
}

export default Time
