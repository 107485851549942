import React, { useEffect } from 'react'
import './Menu.scss'

import menu1 from '../../assets/short-menu/photo_2024-05-17_19-33-05.jpg'
import menu2 from '../../assets/short-menu/photo_2024-05-17_19-35-03.jpg'
import menu3 from '../../assets/short-menu/photo_2024-05-17_19-35-10.jpg'
import menu4 from '../../assets/short-menu/photo_2024-05-17_19-35-16.jpg'
import menu5 from '../../assets/short-menu/photo_2024-05-17_19-35-39.jpg'
import Meal from '../Meal/Meal'

import pdfMenu from '../../assets/file/menu1.pdf'
import pdfDrinks from '../../assets/file/drinks.pdf'
import pdfBar from '../../assets/file/bar.pdf'
import pdfWine from '../../assets/file/wine.pdf'
import MenuMobile from './MenuMobile/MenuMobile'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Menu = () => {
  const openPdfMenu = () => {
    window.open(pdfMenu, '_blank');
  };
  const openPdfDrinks = () => {
    window.open(pdfDrinks, '_blank');
  };
  const openPdfBar = () => {
    window.open(pdfBar, '_blank');
  };
  const openPdfWine = () => {
    window.open(pdfWine, '_blank');
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <div className='menu-desktop'>
        <div className='menu-section-1'>
          <div className='menu-section-1-content'>
            <div className='menu-section-1-block'>
              <Meal
                dataAos="zoom-out-right"
                dataAosDuration="500"
                img={menu1}
                title=' Pizza Margherita'
                desc=''
                price='9'
              />
              <Meal
                img={menu2}
                dataAos="zoom-out-right"
                dataAosDuration="600"
                title='Shrimp and Mango Tacos'
                desc=''
                price='14'
              />
              <Meal
                img={menu3}
                dataAos="zoom-out-right"
                dataAosDuration="700"
                title='Big Plate of Tapas'
                desc=''
                price='29'
              />
              <Meal
                img={menu4}
                dataAos="zoom-out-right"
                dataAosDuration="800"
                title='Seafood Paella + 1 litre of Sangria'
                desc=''
                price='39'
              />
              <Meal
                img={menu5}
                dataAos="zoom-out-right"
                dataAosDuration="900"
                title='Double Smashed Burger with Cheddar and Bacon'
                desc=''
                price='14.50'
              />
            </div>
          </div>
        </div>
        <div className='menu-section-2'>
          <button className='menu-section-2-button' onClick={openPdfMenu} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Download menu</button>
          <button className='menu-section-2-button' onClick={openPdfDrinks} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Drinks</button>
          <button className='menu-section-2-button' onClick={openPdfBar} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Bar</button>
          <button className='menu-section-2-button' onClick={openPdfWine} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Wine</button>
        </div>
      </div>

      <MenuMobile />
    </>
  )
}

export default Menu
