import React, { useState } from 'react'
import './Guests.scss'

const Guests = ({setNumberOfGuests}) => {
  const [isSelected, setIsSelected] = useState()
  const handleChooseNumberOfGuests = (number) => {
    setNumberOfGuests(number)
    setIsSelected(number)
  }

  return (
    <div className='guests'>
      <div>
        <p className='guests__title'>How many guests?</p>
        <div className='guests__container'>
          <div className='guests__content'>
            <button className={`guests__count ${isSelected === 2 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(2)}>2</button>
            <button className={`guests__count ${isSelected === 3 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(3)}>3</button>
            <button className={`guests__count ${isSelected === 4 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(4)}>4</button>
          </div>
          <div className='guests__content'>
            <button className={`guests__count ${isSelected === 5 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(5)}>5</button>
            <button className={`guests__count ${isSelected === 6 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(6)}>6</button>
            <button className={`guests__count ${isSelected === 7 ? 'guests__selected' : ''}`} onClick={() => handleChooseNumberOfGuests(7)}>7</button>
          </div>
        </div>
      </div>

      <p className='guests__text'>More than 8 pers. please contact lamedusarestaurants@gmail.com</p>
    </div>
  )
}

export default Guests
