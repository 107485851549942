import React, { useEffect, useState } from 'react';
import './Reservation.scss';
import { BsPerson } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { CiTimer } from "react-icons/ci";
import { CiCircleCheck } from "react-icons/ci";
import Guests from './components/Guests/Guests';
import DateOfReservation from './components/Date/DateOfReservation';
import Time from './components/Time/Time';
import Confirmation from './components/Confirmation/Confirmation';
import close from '../../assets/icon/close.svg'

const Reservation = ({ setIsReservationPanelOpen }) => {
  const [selectedItem, setSelectedItem] = useState('Guests');
  const [numberOfGuests, setNumberOfGuests] = useState(0)
  const [errorGuests, setErrorGuests] = useState(false)
  const [date, setDate] = useState();
  const [errorDate, setErrorDate] = useState(false)
  const [choosenTime, setChoosenTime] = useState('')
  const [errorTime, setErrorTime] = useState(false)

  const whatToDisplay = () => {
    switch (selectedItem) {
      case 'Guests':
        return <Guests setNumberOfGuests={setNumberOfGuests} />;
      case 'Date':
        return <DateOfReservation date={date} setDate={setDate} />;
      case 'Time':
        return <Time setChoosenTime={setChoosenTime} />;
      case 'Confirm':
        return <Confirmation numberOfGuests={numberOfGuests} date={date} choosenTime={choosenTime} />;
      default:
        return null;
    }
  };

  const isGuestsCountChoosen = () => {
    if (numberOfGuests !== 0) {
      setSelectedItem('Date')
      setErrorGuests(false)
    } else {
      setErrorGuests(true)
    }
  }

  const isDateChoosen = () => {
    if (date) {
      setSelectedItem('Time')
      setErrorDate(false)
    } else {
      setErrorDate(true)
    }
  }

  const isTimeChoosen = () => {
    if (choosenTime !== '') {
      setSelectedItem('Confirm')
      setErrorTime(false)
    } else {
      setErrorTime(true)
    }
  }

  useEffect(() => {
    if (numberOfGuests !== 0) {
      setSelectedItem('Date')
      setErrorGuests(false)
    }
    if (date) {
      setSelectedItem('Time')
      setErrorDate(false)
    }
    if (choosenTime !== '') {
      setSelectedItem('Confirm')
      setErrorTime(false)
    }
  }, [numberOfGuests, date, choosenTime])

  return (
    <div className='reservation'>
      <div className='reservation__top'>
        <p className='reservation__title'>La Medusa</p>
        <img src={close} alt="close" className='reservation__close' onClick={() => setIsReservationPanelOpen(false)} />
      </div>
      <div className='line'></div>
      <div className='reservation__icons'>
        <div className={`reservation__icon-container ${selectedItem === 'Guests' ? 'reservation__icon-container__selected' : ''}`} onClick={() => setSelectedItem('Guests')}>
          <BsPerson className='reservation__icon' color={errorGuests ? 'red' : ''} />
          <p className='reservation__icon__text' style={{ color: errorGuests ? 'red' : '' }}>Guests</p>
        </div>
        <div className={`reservation__icon-container ${selectedItem === 'Date' ? 'reservation__icon-container__selected' : ''}`} onClick={() => isGuestsCountChoosen()}>
          <CiCalendar className='reservation__icon' color={errorDate ? 'red' : ''} />
          <p className='reservation__icon__text' style={{ color: errorDate ? 'red' : '' }}>Date</p>
        </div>
        <div className={`reservation__icon-container ${selectedItem === 'Time' ? 'reservation__icon-container__selected' : ''}`} onClick={() => isDateChoosen()}>
          <CiTimer className='reservation__icon' color={errorTime ? 'red' : ''} />
          <p className='reservation__icon__text' style={{ color: errorTime ? 'red' : '' }}>Time</p>
        </div>
        <div className={`reservation__icon-container ${selectedItem === 'Confirm' ? 'reservation__icon-container__selected' : ''}`} onClick={() => isTimeChoosen()}>
          <CiCircleCheck className='reservation__icon' />
          <p className='reservation__icon__text' >Confirm</p>
        </div>
      </div>

      <div style={{ height: 'fit-content', transition: 'all 0.2 ease' }}>
        {whatToDisplay()}
      </div>
    </div>
  );
};

export default Reservation;
