import React, { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Confirmation.scss';

const Confirmation = ({ numberOfGuests, date, choosenTime }) => {
  const form = useRef();
  const [formError, setFormError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [utmData, setUtmData] = useState({});

  const formatDate = (inputDate) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const input = new Date(inputDate);
    const day = input.getDate();
    const month = months[input.getMonth()];
    const year = input.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID_2,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      );

      const result2 = await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      );

      form.current.reset();
      setFormError(null);
      alert('Message sent successfully!');
    } catch (error) {
      console.error(error.text);
      setFormError('An error occurred while sending the message. Please try again.');
    }
  };

  useEffect(() => {
    const cookies = document.cookie.split(';');
    const utmValues = {};
    cookies.forEach(cookie => {
      const [key, value] = cookie.trim().split('=');
      if (key.startsWith('utm_')) {
        utmValues[key] = value;
      }
    });
    setUtmData(utmValues);
  }, []);

  return (
    <div className='confirmation'>
      <div>
        <div className='confirmation__block'>
          <p className='confirmation__head'>Number of guests</p>
          <p className='confirmation__paragraph'>{numberOfGuests} persons</p>
        </div>
        <div>
          <p className='confirmation__head'>Date and time</p>
          <p className='confirmation__paragraph'>{formatDate(date)} at {choosenTime} PM</p>
        </div>
      </div>
      <form ref={form} className='confirmation__form' onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder='Name' required className='confirmation__input' />
        <input type="email" name="user_email" placeholder='Email' required className='confirmation__input' />
        <PhoneInput
          country={selectedCountry}
          onChange={(value) => {
            setSelectedCountry(value)
          }}
          placeholder="Enter phone number"
          name="user_phone"
          inputProps={{
            required: true,
            autoFocus: true,
            style: { width: '100%' }
          }}
          containerStyle={{ width: '100%' }}
        />
        <input type="hidden" name="number_of_guests" value={numberOfGuests} />
        <input type="hidden" name="date" value={formatDate(date)} />
        <input type="hidden" name="time" value={choosenTime} />
        <input type="hidden" name="user_phone" value={selectedCountry} />
        <input type="hidden" name="utm_campaign" value={utmData.utm_campaign || ''} placeholder='UTM Campaign' className='contact-section-2-input' />
        <input type="hidden" name="utm_medium" value={utmData.utm_medium || ''} placeholder='UTM Medium' className='contact-section-2-input' />
        <input type="hidden" name="utm_source" value={utmData.utm_source || ''} placeholder='UTM Source' className='contact-section-2-input' />
        {formError && <p className="error-message">Error</p>}
        <button className='confirmation__button' type="submit">Confirm</button>
      </form>
    </div>
  )
}

export default Confirmation;
